import { groupValidators } from '@/ui/shared/components/Form/validators/helpers';
import { validateEmail } from '@/ui/shared/components/Form/validators/user/validateEmail';
import { validateRequiredFields } from '@/ui/shared/components/Form/validators/validateRequiredFields';

const requiredFields = [
  { fieldName: 'email', fieldCaption: 'Email' },
  { fieldName: 'entryCode', fieldCaption: 'Entry Code' },
];
const email = { fieldName: 'email', fieldCaption: 'Email' };

export default (values) => groupValidators(
  validateEmail({ ...email, value: values.email }),
  validateRequiredFields(requiredFields, values),
);
